import React, { Component } from 'react';
import { connect } from "react-redux";
import config from '../../config';
import $ from 'jquery';

const {SERVER_URL} = config;

class ImgGallery extends Component {
    handleChangeFutureImg(type_img){
        document.body.classList.add("modal-overflow");
        $('#mediaManagerModal').modal('show');
        const { dispatch } = this.props;
        dispatch({ type: 'IS_GET_IMAGE_FUTURE' });
        dispatch({ type: 'GET_TYPE_IMG_CHANGE', imgchange: type_img})
    }

    handleRemoveImg(type_img){
        const { dispatch } = this.props;
        dispatch({type: type_img, item_url_img: 'img-demo-vhpginseng.jpg'});
    }
    render() {
        var imgGellary1 = this.props.GetImageFrGr.imgGr1;
        var imgGellary2 = this.props.GetImageFrGr.imgGr2;
        var imgGellary3 = this.props.GetImageFrGr.imgGr3;
        var imgGellary4 = this.props.GetImageFrGr.imgGr4;
        
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Accordion -->*/}
                <a href="#idGalleryImageAddProduct" className="d-block card-header py-3 collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="idGalleryImageAddProduct">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="fab fa-empire"></i> Gallery ảnh</h6>
                </a>
                {/*<!-- Content - Blox Hinh anh -->*/}
                <div className="collapse" id="idGalleryImageAddProduct">
                    <div className="card-body row">                                                                                                       
                        <div className="col-md-6 col-sm-6 col-6">
                            <img 
                                src={SERVER_URL + "storage/img/" + imgGellary1} alt="Image Gallery"
                                className="img-fluid img-thumbnail"
                                onClick={() => this.handleChangeFutureImg('GET_IMAGE_GR1')}
                            />
                            <div className="col-md-12 text-center btn-action-addProduct">
                                <div data-placement="top" data-toggle="tooltip" title="Thay ảnh!" className="btn btn-success btn-circle btn-sm" onClick={() => this.handleChangeFutureImg('GET_IMAGE_GR1')}>
                                    <i className="fas fa-check"></i>
                                </div>
                                <div data-placement="top" data-toggle="tooltip" title="Xóa ảnh!" className="btn btn-danger btn-circle btn-sm" onClick={() => this.handleRemoveImg('REMOVE_IMAGE_GR1')} >
                                    <i className="fas fa-trash"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                            <img src={SERVER_URL + "storage/img/" + imgGellary2} alt="Image Gallery" className="img-fluid img-thumbnail" onClick={() => this.handleChangeFutureImg('GET_IMAGE_GR2')}/>
                            <div className="col-md-12 text-center btn-action-addProduct">
                                <div data-placement="top" data-toggle="tooltip" title="Thay ảnh!" className="btn btn-success btn-circle btn-sm" onClick={() => this.handleChangeFutureImg('GET_IMAGE_GR2')}>
                                    <i className="fas fa-check"></i>
                                </div>
                                <div data-placement="top" data-toggle="tooltip" title="Xóa ảnh!" className="btn btn-danger btn-circle btn-sm" onClick={() => this.handleRemoveImg('REMOVE_IMAGE_GR2')} >
                                    <i className="fas fa-trash"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                            <img src={SERVER_URL + "storage/img/" + imgGellary3} alt="Image Gallery" className="img-fluid img-thumbnail" onClick={() => this.handleChangeFutureImg('GET_IMAGE_GR3')}/>
                            <div className="col-md-12 text-center btn-action-addProduct">
                                <div data-placement="top" data-toggle="tooltip" title="Thay ảnh!" className="btn btn-success btn-circle btn-sm" onClick={() => this.handleChangeFutureImg('GET_IMAGE_GR3')}>
                                    <i className="fas fa-check"></i>
                                </div>
                                <div data-placement="top" data-toggle="tooltip" title="Xóa ảnh!" className="btn btn-danger btn-circle btn-sm" onClick={() => this.handleRemoveImg('REMOVE_IMAGE_GR3')} >
                                    <i className="fas fa-trash"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                            <img src={SERVER_URL + "storage/img/" + imgGellary4} alt="Image Gallery" className="img-fluid img-thumbnail" onClick={() => this.handleChangeFutureImg('GET_IMAGE_GR4')}/>
                            <div className="col-md-12 text-center btn-action-addProduct">
                                <div data-placement="top" data-toggle="tooltip" title="Thay ảnh!" className="btn btn-success btn-circle btn-sm" onClick={() => this.handleChangeFutureImg('GET_IMAGE_GR4')}>
                                    <i className="fas fa-check"></i>
                                </div>
                                <div data-placement="top" data-toggle="tooltip" title="Xóa ảnh!" className="btn btn-danger btn-circle btn-sm" onClick={() => this.handleRemoveImg('REMOVE_IMAGE_GR4')} >
                                    <i className="fas fa-trash"></i>
                                </div>
                            </div>
                        </div>                                                    
                                                                                                                    
                    </div>
                </div>
            </div> 
        );
    }
}

export default connect(
    state => ({        
        GetImageFrGr: state.GetImageFrGr
    })
)(ImgGallery);