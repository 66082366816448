import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import config from '../../config';

const API_SERVER_URL = config.API_SERVER_URL;

class TypeProduct extends Component {
    constructor(props) {
        super(props);        
        this.state = { 
            selected: '',
            properties: []
        };
        this.fetchRows = this.fetchRows.bind(this);
    }

    handleChange = (e) => {
        const { dispatch } = this.props;
        this.setState({ selected: e.target.value });
        dispatch({ type: 'INPUT_ID_PROPERTIES_PRODUCT', input_content: e.target.value});
    }

    componentWillMount = () => {
        const { dispatch } = this.props;
        let url = API_SERVER_URL + 'admin/properties';
        axios.get(url).then(
            response => {                  
                this.setState({ properties: response.data });                              
                const idProperties = this.props.id_properties;                     
                this.setState({ selected: idProperties });
                dispatch({ type: 'INPUT_ID_PROPERTIES_PRODUCT', input_content: idProperties});
            }
        ).catch(function(error){
            console.log(error);
        }); 
    }   

    fetchRows() {
        
        if (this.state.properties instanceof Array) {
            

            return this.state.properties.map((object, i) => {                
                return (    
                    <div className="custom-control custom-radio" key={i} index={i}>
                        <input
                            type="radio" 
                            id={"properties" + object.id}
                            name={"properties" + object.id}
                            className="custom-control-input"
                            value={object.id}
                            checked={("properties" + this.state.selected) === ("properties" + object.id)}
                            onChange={(e) => this.handleChange(e)}
                        />
                        <label className="custom-control-label" htmlFor={"properties" + object.id}>{object.name}</label>
                    </div>                                    
                );
            });
          }
    }

    render() {
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Accordion -->*/}
                <a href="#idChonLoaiSP" className="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="idChonLoaiSP">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="far fa-list-alt"></i> Loại sản phẩm</h6>
                </a>
                {/*<!-- Content - Blox Loai San Pham -->*/}
                <div className="collapse show" id="idChonLoaiSP">
                    <div className="card-body">
                        {this.fetchRows()}
                        {/* <div className="custom-control custom-radio">
                            <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadio1">Củ khô</label>
                        </div>
                        <div className="custom-control custom-radio">
                            <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadio2">Nước</label>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        InputContentProduct: state.InputContentProduct
    })
)(TypeProduct);