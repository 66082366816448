import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import $ from 'jquery'

class BtnInsertContent extends Component {
    handleCancelInsert(GetTypePage) {
        const { dispatch } = this.props;
        if (this.props.HandleCancelActionInsert.isAddProduct) {
            // alert(GetTypePage);            
            $('#cancelInsertModal').modal('hide');
            dispatch({ type: GetTypePage });
            this.props.history.push('/list-products');
        } 
        // else {
        //     alert('Not Ok go out!');
        // }
    }

    render() {
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Accordion -->*/}
                <a href="#idActionAddProduct" className="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="idActionAddProduct">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="fas fa-fw fa-cog"></i> Thao tác</h6>
                </a>
                {/*<!-- Content - Blox Thao tác -->*/}
                <div className="collapse show" id="idActionAddProduct">
                    <div className="card-body row d-flex justify-content-center">
                        <button className="btn btn-success btn-icon-split" type='submit'>
                            <span className="icon text-white-50">
                                <i className="fas fa-check"></i>
                            </span>
                            <span className="text">Đăng bài</span>
                        </button>
                        <div data-placement="top" data-toggle="tooltip" title="Lưu nháp!" className="btn btn-warning btn-circle">
                            <i className="fas fa-exclamation-triangle"></i>
                        </div>
                        <div data-toggle="modal" data-target="#cancelInsertModal">
                            <div data-placement="top" data-toggle="tooltip" title="Hủy!" className="btn btn-danger btn-circle">
                                <i className="fas fa-trash"></i>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Cancel Modal--> */}
                <div className="modal fade" id="cancelInsertModal" tabIndex="-1" role="dialog" aria-labelledby="cancleInsertModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="cancleInsertModalLabel">VHP Ginseng - Xác nhận hành động!</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">Chọn "Đồng ý!" nếu bạn muốn thoát khỏi trang này.</div>
                            <div className="modal-footer">
                                <button className="btn btn-info" type="button" data-dismiss="modal">Hủy</button>
                                <button className="btn btn-warning" onClick={() => this.handleCancelInsert(this.props.GetTypePage)}>Đồng ý!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(
    state => ({
        GetTypePage: state.GetTypePage,
        HandleCancelActionInsert: state.HandleCancelActionInsert
    })
)(BtnInsertContent));