import React, { Component } from 'react';
import { connect } from "react-redux";

class DesProductInput extends Component {
    componentDidUpdate(){
        document.getElementById("txtThanhPhanInput").value = this.props.short_structure;
        document.getElementById("txtDesProductInput").value = this.props.short_des;
    }
    handleInputChange = (e) => {
        // console.log('Content was updated:', e.target.getContent());
    }
    render() {
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Accordion -->*/}
                <a href="#DesProductInput" className="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="DesProductInput">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="fas fa-magic"></i> Miêu tả ngắn - Chỉ số SP</h6>
                </a>
                {/*<!-- Content - Blox Hinh anh -->*/}
                <div className="collapse show" id="DesProductInput">
                    <div className="card-body row">                                                 
                        <div className="col-md-12 col-sm-12 col-12 form-group">
                            <textarea 
                                className="form-control"
                                id="txtThanhPhanInput"
                                name="txtThanhPhanInput"
                                ref="txtThanhPhanInput"
                                rows="2"                                                            
                                placeholder="Thành phần"                                
                                onChange={this.handleInputChange}
                                >
                            </textarea>                                
                        </div>
                        <div className="col-md-12 col-sm-12 col-12 form-group">
                            <textarea
                                className="form-control"
                                id="txtDesProductInput"
                                name="txtDesProductInput"
                                ref="txtDesProductInput"
                                rows="2"                                                            
                                onChange={this.handleInputChange}
                                placeholder="Đối tượng sử dụng">
                            </textarea>
                        </div>
                        <div className="col-md-12 col-sm-12 col-12 form-group has-feedback">
                            <input
                                type="text"
                                className="form-control has-feedback-left"
                                id="inputSanXuat"
                                placeholder="Sản xuất"                            
                                defaultValue={this.props.san_xuat}
                                onChange={this.handleInputChange}
                            />
                            <span className="fas fa-house-damage form-control-feedback left" aria-hidden="true"></span>
                        </div>                    
                        <div className="col-md-12 col-sm-12 col-12 form-group has-feedback">
                            <input
                                type="text"
                                className="form-control has-feedback-left"
                                id="inputNhanHieu"
                                placeholder="Nhãn hiệu"                            
                                defaultValue={this.props.nhan_hieu}
                                onChange={this.handleInputChange}
                            />
                            <span className="fab fa-stumbleupon-circle form-control-feedback left" aria-hidden="true"></span>
                        </div>                    
                        <div className="col-md-12 col-sm-12 col-12 form-group has-feedback">
                            <input type="text"
                                className="form-control has-feedback-left"
                                id="inputBarcode"
                                placeholder="Mã vạch"
                                defaultValue={this.props.ma_vach}
                                onChange={this.handleInputChange}
                            />
                            <span className="fas fa-barcode form-control-feedback left" aria-hidden="true"></span>
                        </div>
                        <div className="col-md-12 col-sm-12 col-12 form-group has-feedback">
                            <input type="text"
                                className="form-control has-feedback-left"
                                id="inputQuyCach"
                                placeholder="Quy cách"
                                defaultValue={this.props.quy_cach}
                                onChange={this.handleInputChange}
                            />
                            <span className="fas fa-truck-loading form-control-feedback left" aria-hidden="true"></span>
                        </div>                    
                        <div className="col-md-12 col-sm-12 col-12 form-group has-feedback">            
                            <input type="text"
                                className="form-control has-feedback-left"
                                id="inputCongBo"
                                placeholder="Số công bố"
                                defaultValue={this.props.so_cong_bo}
                                onChange={this.handleInputChange}
                            />
                            <span className="fas fa-chess-queen form-control-feedback left" aria-hidden="true"></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({        
        InputContentProduct: state.InputContentProduct
    }))
(DesProductInput);