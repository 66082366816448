import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import config from '../../config';

const API_SERVER_URL = config.API_SERVER_URL;

class WebDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            websites: [],
            listWebsites: []
        };
        this.fetchRows = this.fetchRows.bind(this);
    }

    handleChange = (e) => {        
        let isChecked = e.target.checked;                       
        const { dispatch } = this.props;
        let joinWeb = '';
        let getWeb = [];                                 
        if(isChecked){
            // Them value vao mang!    
            getWeb = this.state.listWebsites.concat(e.target.value);            
        }else{
            // xoa value khoi mang            
            getWeb = [...this.state.listWebsites]
            var index = getWeb.indexOf(e.target.value)            
            if (index !== -1) {
                getWeb.splice(index, 1);                
            }                  
        }        
        this.setState({listWebsites: getWeb});
        joinWeb = getWeb.join(', ');
        dispatch({ type: 'INPUT_LIST_ID_WEBSITE_PRODUCT', input_content: joinWeb});
        // console.log(getWeb);
    }            

    componentWillMount = () => {
        const { dispatch } = this.props;
        let url = API_SERVER_URL + 'admin/website';        
        axios.get(url).then(
            response => {                  
                this.setState({ websites: response.data });
                let getWebsite = this.props.list_id_website_product;                
                if(getWebsite.includes(",")){
                    let website =  getWebsite.split(', ');
                    this.setState({ listWebsites: website }); 
                    dispatch({ type: 'INPUT_LIST_ID_WEBSITE_PRODUCT', input_content: this.state.listWebsites});                                   
                }else{                    
                    this.setState({ listWebsites: this.state.listWebsites.concat(getWebsite) });
                    dispatch({ type: 'INPUT_LIST_ID_WEBSITE_PRODUCT', input_content: this.state.listWebsites});                                   
                }
            }
        ).catch(function(error){
            console.log(error);
        });     
    }    
    
    fetchRows(list_id_website_product) {
        if (this.state.websites instanceof Array) {
            return this.state.websites.map((object, i) => {                
                return (  
                    <div className="custom-control custom-checkbox mr-sm-2" key={i} index={i}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id={"web" + object.id}
                            name={"web" + object.id}
                            value={object.id}
                            defaultChecked={list_id_website_product.includes(object.id) ? true : false}
                            onChange={(e) => this.handleChange(e)}
                        />
                        <label className="custom-control-label" htmlFor={"web" + object.id}>{object.url}</label>
                    </div>                                                       
                );
            });
          }
    }

    render() {
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Accordion -->*/}
                <a href="#idChonWeb" className="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="idChonWeb">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="fas fa-clipboard-list"></i> Website hiển thị</h6>
                </a>
                {/*<!-- Content - Blox Website hien thi -->*/}
                <div className="collapse show" id="idChonWeb">
                    <div className="card-body">
                        {this.fetchRows(this.props.list_id_website_product)}
                        {/* <div className="custom-control custom-checkbox mr-sm-2">
                            <input type="checkbox" className="custom-control-input" id="web1" />
                            <label className="custom-control-label" htmlFor="web1">http://daedong.com.vn</label>
                        </div>
                        <div className="custom-control custom-checkbox mr-sm-2">
                            <input type="checkbox" className="custom-control-input" id="web2" />
                            <label className="custom-control-label" htmlFor="web2">http://vhpginseng.com.vn</label>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        InputContentProduct: state.InputContentProduct
    })
)(WebDisplay);