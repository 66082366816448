import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { PostData } from './services/PostData';
import { Preloader } from './animate/Preloader';
import { Auth } from './fake.auth';
import logo from '../lib/lib-admin/img/logo_admin_line1.png';
// import { connect } from "react-redux";

class LoginForm extends Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      'email': '',
      'password': '',
      redirectToReferrer: false
    }
    this.onChange = this.onChange.bind(this);
    this.login = this.login.bind(this);

  }

  // componentWillReceiveProps(newProps) {
  //   console.log("componentWillReceiveProps: " + newProps);
  // }
  // shouldComponentUpdate(newProps, newState) {
  //   return true;
  // }
  // componentWillUpdate(nextProps, nextState) {
  //   console.log('componentWillUpdate - Reduser Auth:' + this.props.Auth);
  // }
  // componentDidUpdate(prevProps, prevState) {
  //   console.log('componentDidUpdate - Reduser Auth:' + this.props.Auth);
  // }
  componentDidMount(e) {
    this._isMounted = true;
    document.body.classList.add("isLogin");
    document.body.classList.add("loaded");    
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  login = (e) => {
    e.preventDefault();
    document.body.classList.remove("loaded");
    const user = {
      email: this.state.email,
      password: this.state.password
    }

    PostData('login', user).then((result) => {
      let responseJSON = result;
      if (responseJSON.access_token) {
        localStorage.setItem('userData', responseJSON.access_token);
        // const { dispatch } = this.props;        
        // dispatch({ type: 'CHECK_AUTH' });
        // console.log('Auth Reducer: ' + this.props.Auth);        
        Auth.loginAuth(() => {          
          this.setState({ redirectToReferrer: true });
        });
      }

      if (responseJSON.error) {
        alert(responseJSON.error);
      }
      document.body.classList.add("loaded");
    });
  }

  render() {   
    if(localStorage.getItem('userData')){
      let { from } = this.props.location.state || { from: { pathname: "/" } };
      let { redirectToReferrer } = this.state;      
      if(redirectToReferrer || localStorage.getItem('userData')) {
        if(from.pathname ==='/'){
          Auth.loginAuth(() => {
            this.props.history.push('/home');
          });
        } else {
          Auth.loginAuth(() => {
            this.props.history.push(from);
          });
        }
        
      }
    }  

    return (
      <div className="container">
        <Preloader />
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div className="card card-signin my-5">
              <div className="card-body">
                <h1 className="card-title text-center">
                  <img src={logo} className="logoVHPGinseng-login-a" alt="Logo VHP Ginseng" />
                </h1>
                <form className="form-signin" >
                  {/* onSubmit={this.login} */}
                  <div className="form-label-group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="Email của bạn!"
                      value={this.state.email}
                      onChange={this.onChange}
                      required
                      autoFocus
                    />
                    <label htmlFor="email">Email</label>
                  </div>

                  <div className="form-label-group">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.onChange}
                      required
                    />
                    <label htmlFor="password">Password</label>
                  </div>

                  <div className="custom-control custom-checkbox mb-3">
                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                    <label className="custom-control-label" htmlFor="customCheck1" style={{ color: '#fff' }}>Remember password</label>
                  </div>
                  <button className="btn btn-lg btn-danger btn-block text-uppercase" onClick={this.login}>Đăng nhập</button>                                    
                  <p className="copyright text-center" style={{ color: '#fff', fontSize: '14px', marginTop:'30px' }}>Copyright &copy; 2019 All Rights Reserved - TrangIT!<br />Design By TrangIT - Sponsored by VHP Group</p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginForm);
// export default withRouter(connect(state => ({
//   isAuth: state.isAuthReduser
// }))(LoginForm));