import React, { Component } from 'react';
import { connect } from "react-redux";
import $ from 'jquery';
import MediaManager from '../media/media.manager';

class GetMediaModal extends Component {
    handleCloseModal(){
        $('#mediaManagerModal').modal('hide');
        document.body.classList.remove("modal-overflow");   
        const { dispatch } = this.props;
        if(this.props.GetImageContent){
            dispatch({ type: 'IS_GET_IMAGE_CONTENT' });
        }
        if(this.props.GetImageFuture){
            dispatch({ type: 'IS_GET_IMAGE_FUTURE' });
        }
    }    

    render() {
        return (
            <div className="modal fade bd-example-modal-xl" id="mediaManagerModal" tabIndex="-1" role="dialog" aria-labelledby="mediaManagerModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title font-weight-bold text-primary" id="mediaManagerModalLabel"><i className="fas fa-biohazard"></i> Quản lý thư viện!</h5>
                            <button type="button" className="close" onClick={() => this.handleCloseModal()} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <MediaManager />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => this.handleCloseModal()}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(GetMediaModal);