import React, { createRef, Component } from 'react';
import ReactDOM from 'react-dom';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import config from '../../config';
import LeftSideBar from '../left-sidebar/left.sidebar';
import Navigation from '../menu/navigation';
import ListMedia from './list.media';
import {Preloader} from '../animate/Preloader';
import { connect } from "react-redux";

const {API_SERVER_URL} = config;

class MediaManager extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (files) => {
            files.map(file => (
                this.setState(
                    {
                        files: [...this.state.files, Object.assign(file, {
                            preview: URL.createObjectURL(file)
                        })]
                    })
            )
            );
            // console.log(this.state.files);
        };

        this.onDropAccepted = () => {           
            ReactDOM.render(
                <button style={{margin:'15px'}} type="button" className="btn btn-danger" onClick={this.handlerUpload}><i className="fas fa-cloud-upload-alt"></i> Thực hiện Upload</button>,
                document.getElementById('wrapBtnUpload')
            );
        }
        
        this.state = {
            files: [],
            image: '',
            imageList: []
        };  
        
        this.handlerRemovePrV = this.handlerRemovePrV.bind(this);
        this.handlerUpload = this.handlerUpload.bind(this);        
    }    

    componentWillMount = () => {
        // Make sure to revoke the data uris to avoid memory leaks
        
        document.body.className = '';
        document.body.classList.add("sidebar-toggled");
        document.body.id = "page-top";
        document.body.classList.add("isMediaManagerAdmin");
        document.body.classList.remove("isLogin");             
        this.state.files.forEach(file => URL.revokeObjectURL(file.preview));   
    }   

    handlerUpload(e) {
        e.preventDefault();
        console.log('Upload handler!');
        ReactDOM.render(
            '',
            document.getElementById('wrapBtnUpload')
        );
        let url = API_SERVER_URL + 'admin/image/upload';        
        const datas = this.state.files;  
        const fd = new FormData();
        datas.map((data) => fd.append('image[]', data, data.name));  
        document.body.classList.remove("loaded");        
        axios.post(url, fd)
        .then((response) => {
            // console.log(response.data.UpLoadSuccess);
            //   console.log(response.data.imageList);     
            this.setState({ imageList: response.data.imageList, files: [] })        
            const {imageList} = this.state;
            let newImageList = imageList.reverse();
            this.setState({
                imageList: newImageList.sort((a,b) => a.id > b.id)
            });           
            
            const { dispatch } = this.props;
            dispatch({ type: 'GET_IMAGE', item: this.state.imageList });                     
            document.body.classList.add("loaded");
        })
        .catch(function (error) {
          console.log(error);
        });                          
    
    }

    handlerRemovePrV(index, e) {
        e.preventDefault();        
        // console.log('Remove handler' + index);
        this.state.files.splice(index, 1);
        this.setState(this.state);
        //Remove Button Upload
        if(this.state.files.length === 0) {
            // console.log('State null');
            ReactDOM.render(
                '',
                document.getElementById('wrapBtnUpload')
            );
        }        
    }

    render() {

        const thumbsContainer = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        };

        const wrapPreviewIMG = {
            width: 120,
            height: 'auto',
            textAlign: 'center',
            marginTop: 10,
            marginBottom: 8,
            marginRight: 8,
            padding: 3,
        }

        const thumb = {
            display: 'inline-flex',
            borderRadius: 2,
            border: '1px solid #eaeaea',
            width: 120,
            height: 120,
            padding: 4,
            boxSizing: 'border-box'
        };

        const thumbInner = {
            display: 'flex',
            minWidth: 0,
            overflow: 'hidden'
        };

        const img = {
            display: 'block',
            width: 'auto',
            height: '100%'
        };

        const thumbs = this.state.files.map((file, index) => (
            <div className="wrap-preview-img" style={wrapPreviewIMG} key={index} index={index} data-letter={index}>
                <div style={thumb}>
                    <div style={thumbInner}>
                        <img
                            src={file.preview}
                            style={img}
                            alt="thumb-Preview"
                        />

                    </div>
                </div>
                <i style={{ display: 'block', width: '100%', wordWrap: 'break-word' }}>{file.name}</i>
                <button style={{margin:'15px'}} type="button" className="btn btn-danger" onClick={(e) => this.handlerRemovePrV(index, e)}><i className="fas fa-trash"></i> Xóa</button>
            </div>
        ));                    

        const dropzoneRef = createRef();
        const openDialog = () => {
            // Note that the ref is set async,
            // so it might be null at some point 
            if (dropzoneRef.current) {
                dropzoneRef.current.open()
            }
        }; 

        let leftSideBar, NaviMenu, TitleManager;
        if(document.body.classList.contains('isAddProductsAdmin') || document.body.classList.contains('isEditProductAdmin')){
            leftSideBar = NaviMenu = TitleManager = null;
        }else {
            leftSideBar = <LeftSideBar/>;
            NaviMenu = <Navigation/> 
            TitleManager = <h4 className="font-weight-bold text-primary"><i className="fas fa-biohazard"></i> Media Manager!</h4>
        } 

        return (
            <div className="wrapper-full">
                <Preloader/>
                {/*<!-- Page Wrapper -->*/}
                <div id="wrapper">
                    {/* Begin Left Side-bar */}
                   
                    {/* <LeftSideBar /> */}
                    {leftSideBar}
                    {/* End Left Side-bar */}

                    {/* <!-- Content Wrapper --> */}
                    <div id="content-wrapper" className="d-flex flex-column">

                        {/* <!-- Main Content --> */}
                        <div id="content">

                            {/* <!-- Topbar --> */}
                            {/* <Navigation /> */}
                            {NaviMenu}
                            {/* <!-- End of Topbar --> */}

                            {/* <!-- Begin Page Content --> */}
                            <div className="container-fluid">
                                <div className="row">                    
                                    {TitleManager}
                                    <div className="col-md-12 col-lg-12">
                                        <Dropzone  
                                            onDrop={this.onDrop}
                                            onDropAccepted={this.onDropAccepted}
                                            ref={dropzoneRef} 
                                            accept={'image/*'}
                                            noClick noKeyboard>
                                            {({ getRootProps, getInputProps }) => (
                                                <section className="wrap-dropzone">
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input name="file" {...getInputProps()} />
                                                        <p>Click để chọn files hoặc kéo thả files vào đây!</p>
                                                        <button type="button" className="btn btn-success" onClick={openDialog}><i className="fas fa-folder-open"></i> Chọn Files!</button>                                        
                                                        <div id="wrapBtnUpload"></div>
                                                    </div>
                                                    <aside style={thumbsContainer}>
                                                        {thumbs}
                                                    </aside>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                </div>

                                {/* List media */}
                                <ListMedia/>
                                {/* End List media */}
                            </div>
                            {/* <!-- /.container-fluid --> */}                            
                        </div>
                        {/* <!-- End of Main Content --> */}

                        {/* <!-- Footer --> */}
                        <footer className="sticky-footer bg-white">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright &copy; VHP Group - Hệ thống quản trị web - Design by TrangIT</span>
                                </div>
                            </div>
                        </footer>
                        {/* <!-- End of Footer --> */}

                    </div>
                    {/* <!-- End of Content Wrapper --> */}

                </div>
                {/* <!-- End of Page Wrapper --> */}

                {/* <!-- Scroll to Top Button--> */}
                <button className="scroll-to-top rounded">
                    <i className="fas fa-angle-up"></i>
                </button>

            </div>
        );
    }
}

// export default MediaManager;
export default connect(
    state => ({
        GetImage: state.ImageReducer        
    })
)(MediaManager);