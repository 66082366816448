import React, { Component } from 'react';

const divStyle = { 
    margin: '10% 0 0 0',
}

class NotFound extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="card text-white bg-danger" style={divStyle}>                  
                  <div className="card-body">
                    <h4 className="card-title">404 | Not Found</h4>
                    <p className="card-text"><a href="/" title="Back Home!">Back Home</a></p>
                  </div>
                </div>
            </div>
        );
    }
}

export default NotFound;