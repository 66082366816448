import { createStore, compose, combineReducers } from 'redux';
import { isAuthReduser } from './fake.auth';
import { ImageReducer } from './image.reducer';
import { GetEditor } from './get.editor';
import { GetImageContent } from './get.image.content';
import { GetImageFrGr } from './get.image.fr.gr';
import { GetImageFuture } from './get.image.future';
import { GetTypeImgChange } from './get.type.img.change';
import { GetTypePage } from './get.type.page';
import { HandleCancelActionInsert } from './handle.cancel.action.insert';
import { ProductReducer } from './product.reducer';
import { InputContentProduct } from './input.content.product';

const rootReducer = combineReducers({
    isAuthReduser,
    ProductReducer,
    ImageReducer,
    GetEditor,
    GetImageContent,
    GetImageFrGr,
    GetImageFuture,
    GetTypeImgChange,
    GetTypePage,
    HandleCancelActionInsert,
    InputContentProduct
});

const store = createStore(rootReducer, compose(
    window.devToolsExtension ? window.devToolsExtension() : f => f
));

// const store = createStore(rootReducer);

export default (store);
