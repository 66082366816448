const config = {

//     'API_SERVER_URL' : 'http://localhost:8000/api/',
//     'SERVER_URL': 'http://localhost:8000/'

// http://api.daedong.com.vn/api

    'API_SERVER_URL' : 'http://api.daedong.com.vn/api/',
    'SERVER_URL': 'http://api.daedong.com.vn/'
}
export default config;