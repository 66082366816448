export const funcHandle = {
    sidebarToggle : false,
    _sidebarToggle () {        
        this.sidebarToggle = !this.sidebarToggle;        
        if(this.sidebarToggle){
          document.body.classList.remove("sidebar-toggled");
          document.getElementById('accordionSidebar').classList.remove("toggled");
        } else {
          document.body.classList.add("sidebar-toggled");
          document.getElementById('accordionSidebar').classList.add("toggled");
        }    
    }
}

export const currencyFormat = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

    
    