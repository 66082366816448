import React, { Component } from 'react';
import { connect } from "react-redux";
import config from '../../config';
import $ from 'jquery';

const {SERVER_URL} = config;

class ImgFuture extends Component {
    handleChangeFutureImg(type_img){
        document.body.classList.add("modal-overflow");
        $('#mediaManagerModal').modal('show');
        const { dispatch } = this.props;
        dispatch({ type: 'IS_GET_IMAGE_FUTURE' });
        dispatch({ type: 'GET_TYPE_IMG_CHANGE', imgchange: type_img});
    }

    handleRemoveImg(type_img){
        const { dispatch } = this.props;
        dispatch({type: type_img, item_url_img: 'img-demo-vhpginseng.jpg'});
    }    

    // componentWillMount = () => {
    //     const { dispatch } = this.props;
    //     if(this.props.img_future && (this.props.img_future!==null)){
    //         dispatch({type: 'GET_IMAGE_FR', item_url_img: this.props.img_future});
    //     }else{
    //         dispatch({ type: 'GET_IMAGE_FR', item_url_img: 'img-demo-vhpginseng.jpg'});
    //     }
        
    // }

    render() {
        // var imgFuture = (this.props.img_future !== null ? this.props.img_future  : this.props.GetImageFrGr.imgFr);
             
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Accordion -->*/}
                <a href="#idImageAddProduct" className="d-block card-header py-3 collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="idImageAddProduct">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="far fa-image"></i> Ảnh đại diện</h6>
                </a>
                {/*<!-- Content - Blox Hinh anh -->*/}
                <div className="collapse" id="idImageAddProduct">
                    <div className="card-body row">
                        <div className="col-md-12">
                            <img
                                src={SERVER_URL + "storage/img/" + this.props.GetImageFrGr.imgFr}
                                alt="Image future"
                                className="img-fluid img-thumbnail"
                                onClick={() => this.handleChangeFutureImg('GET_IMAGE_FR')}
                            />
                            <div className="col-md-12 text-center btn-action-addProduct">
                                <div data-placement="top" data-toggle="tooltip" title="Thay ảnh!" className="btn btn-success btn-circle btn-sm" onClick={() => this.handleChangeFutureImg('GET_IMAGE_FR')}>
                                    <i className="fas fa-check"></i>
                                </div>
                                <div data-placement="top" data-toggle="tooltip" title="Xóa ảnh!" className="btn btn-danger btn-circle btn-sm" onClick={() => this.handleRemoveImg('REMOVE_IMAGE_FR')} >
                                    <i className="fas fa-trash"></i>
                                </div>
                            </div>
                        </div>                                                                                                                                                                                                                                                       
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({        
        GetImageFrGr: state.GetImageFrGr
    })
)(ImgFuture);