export const Auth = {
    isAuthenticated: false,
    loginAuth(cb) {
        this.isAuthenticated = true;
        setTimeout(cb, 50); // fake async
    },
    logutAuth(cb) {
        this.isAuthenticated = false;
        setTimeout(cb, 50);
    }
};
  