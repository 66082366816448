import React from 'react';
import '../../dist/css/preloader.css';
export const Preloader = () => {
    
        return (
            <div id="loader-wrapper">
                <div id="loader"></div>

                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>

            </div>            
        );
    
}