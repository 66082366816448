import React, { Component } from 'react';
import LeftSideBar from '../left-sidebar/left.sidebar';
import Navigation from '../menu/navigation';
import { connect } from "react-redux";
// import config from '../../config';
import $ from 'jquery';
import MainInsertContent from '../element/main.insert.content';
import BtnInsertContent from '../element/btn.insert.content'
import Footer from '../footer/footer';
import ImgFuture from '../element/img.future';
import ImgGallery from '../element/img.gallery';
import PriceProduct from '../element/price.product';
import NumberDisplay from '../element/number.display';
import CategoryProduct from '../element/category.product';
import TypeProduct from '../element/type.product';
import WebDisplay from '../element/web.display';
import KeySeo from '../element/key.seo';
import DesSeo from '../element/des.seo';
import ViewsDay from '../element/views.day';
import GetMediaModal from '../element/get.media.modal';
import DesProductInput from '../element/des.products.input';

// const {SERVER_URL, API_SERVER_URL} = config;

class AddProduct extends Component {   
    componentWillMount(){
        $('[data-toggle="tooltip"]').tooltip();
        const { dispatch } = this.props;     
        dispatch({ type: 'GET_TYPE_PAGE', type_page: 'isAddProductsAdmin' });    
        dispatch({ type: 'isAddProductsAdmin' });          
        dispatch({ type: 'GET_IMAGE_FR', item_url_img: 'img-demo-vhpginseng.jpg'});
        dispatch({ type: 'GET_IMAGE_GR1', item_url_img: 'img-demo-vhpginseng.jpg'});
        dispatch({ type: 'GET_IMAGE_GR2', item_url_img: 'img-demo-vhpginseng.jpg'});
        dispatch({ type: 'GET_IMAGE_GR3', item_url_img: 'img-demo-vhpginseng.jpg'});
        dispatch({ type: 'GET_IMAGE_GR4', item_url_img: 'img-demo-vhpginseng.jpg'});
        
        //Set default null content product
        dispatch({ type: 'INPUT_NAME_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_ALIAS_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_ID_PROPERTIES_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_PRICE_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_PRICE_SALE_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_NUMBER_DISPLAY_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_CONTENT_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_SHORT_STRUCTURE_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_SHORT_DES_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_SAN_XUAT_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_NHAN_HIEU_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_MA_VACH_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_QUY_CACH_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_SO_CONG_BO_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_KEY_SEO_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_DES_SEO_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_LIST_ID_CATE_PRODUCT', input_content: ''});
        dispatch({ type: 'INPUT_LIST_ID_WEBSITE_PRODUCT', input_content: ''}); 
    }
    componentDidMount(e) {
        document.body.className = '';
        document.body.classList.add("sidebar-toggled");
        document.body.id = "page-top";
        document.body.classList.add("isAddProductsAdmin");
        document.body.classList.remove("isLogin");        
    }

    render() {               
        return (
            <div className="wrapper-full">                
                {/*<!-- Page Wrapper -->*/}
                <div id="wrapper">
                    {/* Begin Left Side-bar */}
                    <LeftSideBar />
                    {/* End Left Side-bar */}

                    {/* <!-- Content Wrapper --> */}
                    <div id="content-wrapper" className="d-flex flex-column">

                        {/* <!-- Main Content --> */}
                        <div id="content">

                            {/* <!-- Topbar --> */}
                            <Navigation />
                            {/* <!-- End of Topbar --> */}

                            {/* <!-- Begin Page Content --> */}
                            <div className="container-fluid">
                                <form>
                                    {/* <!-- Content Row --> */}
                                    <div className="row">
                                        {/* Left add product */}
                                        <div className="col-md-9 col-sm-9 col-12">  
                                            
                                            {/*<!-- Nội dung chính sản phẩm -->*/}
                                            <MainInsertContent title='Thêm sản phẩm mới'/>
                                            
                                        </div>
                                        {/* End Left add product */}

                                        {/* Right add product */}
                                        <div className="col-md-3 col-sm-3 col-12">
                                            {/*<!-- Blox Thao tác -->*/}                                            
                                            <BtnInsertContent/>                                            
                                            {/*<!-- End Blox Thao tác -->*/}

                                            {/* Thêm thành phần + Thông tin sản xuất của sản phẩm */}
                                            <DesProductInput/>                                            

                                        </div>                                    
                                        {/* End Right add product */}
                                    </div>

                                    <div className="row">
                                        {/* Giá sản phẩm */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            {/*<!-- Blox Giá bán -->*/}
                                            <PriceProduct/>
                                            {/*<!-- Blox Thứ tự -->*/}
                                            <NumberDisplay/>
                                        </div>                                        
                                        {/* End Giá sản phẩm */}
                                        
                                        {/* Blox Hình ảnh */}
                                        <div className="col-md-4 col-sm-4 col-12">                                            
                                            <ImgFuture/>
                                            {/*<!-- End Blox Hình ảnh -->*/}

                                            {/* Blox Gallery Hình ảnh */}
                                            <ImgGallery/>                                       
                                            {/* End Gallery Hình ảnh */}                                                                                        
                                        </div>                                        
                                        

                                        {/* Thao tác second */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            {/*<!-- Blox Thao tác -->*/}
                                            <BtnInsertContent/>                                            
                                        </div>                                        
                                        {/* End Thao tác second */}                                            
                                    </div>
                                    {/* Chọn danh mục / Chọn loại sản phẩm / Chọn Webstie */}
                                    <div className="row">
                                        {/* Danh Muc San Pham */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            {/*<!-- Blox Giá bán -->*/}
                                            <CategoryProduct
                                                list_id_cate_product={'1'}
                                            />
                                        </div>                                        
                                        {/* End Danh Muc San Pham */}
                                        
                                        {/* Loai San Pham */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            {/*<!-- Blox Loai San Pham -->*/}
                                            <TypeProduct
                                                id_properties={'1'}
                                            />
                                        </div>                                        
                                        {/* End Loai San Pham */}

                                        {/* Website hien thi second */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            {/*<!-- Blox Website hien thi -->*/}
                                            <WebDisplay
                                                list_id_website_product={'1'}
                                            />
                                        </div>                                        
                                        {/* End Website hien thi second */}                                            
                                    </div>                                           
                                    {/* End Chọn danh mục / Chọn loại sản phẩm / Chọn Webstie */}                                    

                                    {/* SEO Content */}
                                    <div className="row">
                                        {/* KEY SEO */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            <KeySeo/>
                                        </div> 
                                        {/* END KEY SEO */}    

                                        {/* Description SEO */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            <DesSeo/>
                                        </div>                                        
                                        {/* End Description SEO */}

                                        {/* Views / Day */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            <ViewsDay/>
                                        </div>
                                        {/* End Views / Day */}
                                    </div>
                                    {/* End SEO Content */}

                                    {/* <!-- Content Row --> */}
                                </form>
                                {/* Get Media Manager */}
                                <GetMediaModal/>
                            </div>
                            {/* <!-- /.container-fluid --> */}                            
                        </div>
                        {/* <!-- End of Main Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer/>
                        {/* <!-- End of Footer --> */}

                    </div>
                    {/* <!-- End of Content Wrapper --> */}

                </div>
                {/* <!-- End of Page Wrapper --> */}

                {/* <!-- Scroll to Top Button--> */}
                {/* <button className="scroll-to-top rounded">
                    <i className="fas fa-angle-up"></i>
                </button> */}

            </div>
        );
    }
}

export default connect(
    state => ({
        GetEditor: state.GetEditor,
        GetImageContent: state.GetImageContent,        
        GetImageFuture: state.GetImageFuture
    })
)(AddProduct);