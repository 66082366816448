const defaultState = {
    id: '',
    name: '',
    alias: '',
    id_properties: '',
    price: '',
    price_sale: '',
    number_display: '',
    content: '',
    short_structure: '',
    short_des: '',
    san_xuat: '',
    nhan_hieu: '',
    ma_vach: '',
    quy_cach: '',
    so_cong_bo: '',
    key_seo: '',
    des_seo: '',    
    list_id_cate_product: '',    
    list_id_website_product: ''
};
export const InputContentProduct = (state = defaultState, action) => {
    switch (action.type) {
        case 'INPUT_NAME_PRODUCT':
            return { ...state, name: action.input_content };
        case 'INPUT_ALIAS_PRODUCT':
            return { ...state, alias: action.input_content };
        case 'INPUT_ID_PROPERTIES_PRODUCT':
            return { ...state, id_properties: action.input_content };
        case 'INPUT_PRICE_PRODUCT':
            return { ...state, price: action.input_content };
        case 'INPUT_PRICE_SALE_PRODUCT':
            return { ...state, price_sale: action.input_content };
        case 'INPUT_NUMBER_DISPLAY_PRODUCT':
            return { ...state, number_display: action.input_content };
        case 'INPUT_CONTENT_PRODUCT':
            return { ...state, content: action.input_content };
        case 'INPUT_SHORT_STRUCTURE_PRODUCT':
            return { ...state, short_structure: action.input_content };
        case 'INPUT_SHORT_DES_PRODUCT':
            return { ...state, short_des: action.input_content };
        case 'INPUT_SAN_XUAT_PRODUCT':
            return { ...state, san_xuat: action.input_content };
        case 'INPUT_NHAN_HIEU_PRODUCT':
            return { ...state, nhan_hieu: action.input_content };
        case 'INPUT_MA_VACH_PRODUCT':
            return { ...state, ma_vach: action.input_content };
        case 'INPUT_QUY_CACH_PRODUCT':
            return { ...state, quy_cach: action.input_content };
        case 'INPUT_SO_CONG_BO_PRODUCT':
            return { ...state, so_cong_bo: action.input_content };
        case 'INPUT_KEY_SEO_PRODUCT':
            return { ...state, key_seo: action.input_content };
        case 'INPUT_DES_SEO_PRODUCT':
            return { ...state, des_seo: action.input_content };
        case 'INPUT_LIST_ID_CATE_PRODUCT':
            return { ...state, list_id_cate_product: action.input_content };
        case 'INPUT_LIST_ID_WEBSITE_PRODUCT':
            return { ...state, list_id_website_product: action.input_content };                                                                                                                 
        default:
            return state
    }
}