import React, { Component } from 'react';
import { connect } from 'react-redux';

class DesSeo extends Component {
    componentDidUpdate(){
        document.getElementById("idDesSeoContent").value = this.props.des_seo;
    }
    handleInputChange = (e) => {
        // console.log('Content was updated:', e.target.getContent());
    }
    render() {
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Accordion -->*/}
                <a href="#idDescriptionSeo" className="d-block card-header py-3 collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="idDescriptionSeo">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="fas fa-audio-description"></i> Description SEO</h6>
                </a>
                {/*<!-- Content - Blox Description SEO -->*/}
                <div className="collapse" id="idDescriptionSeo">
                    <div className="card-body row">
                        <div className="form-group col-12">
                            <textarea
                                className="form-control"
                                id="idDesSeoContent"
                                rows="5"
                                placeholder="Des SEO"                                
                                onChange={this.handleInputChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        InputContentProduct: state.InputContentProduct
    })
)(DesSeo);