import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Preloader } from '../animate/Preloader';
import axios from 'axios';
import { connect } from "react-redux";
import LeftSideBar from '../left-sidebar/left.sidebar';
import Navigation from '../menu/navigation';
import Footer from '../footer/footer';
import MainInsertContent from '../element/main.insert.content';
import BtnInsertContent from '../element/btn.insert.content';
import DesProductInput from '../element/des.products.input';
import ImgFuture from '../element/img.future';
import ImgGallery from '../element/img.gallery';
import PriceProduct from '../element/price.product';
import NumberDisplay from '../element/number.display';
import CategoryProduct from '../element/category.product';
import TypeProduct from '../element/type.product';
import WebDisplay from '../element/web.display';
import KeySeo from '../element/key.seo';
import DesSeo from '../element/des.seo';
import ViewsDay from '../element/views.day';
import GetMediaModal from '../element/get.media.modal';
import config from '../../config';
import $ from 'jquery';

const API_SERVER_URL = config.API_SERVER_URL;

class EditProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            name: '',
            alias: '',
            id_properties: '',
            img_future: '',
            img_gellary1: '',
            img_gellary2: '',
            img_gellary3: '',
            img_gellary4: '',
            price: '',
            price_sale: '',
            number_display: '',
            content: '',
            short_structure: '',
            short_des: '',
            san_xuat: '',
            nhan_hieu: '',
            ma_vach: '',
            quy_cach: '',
            so_cong_bo: '',
            key_seo: '',
            des_seo: '',
            list_id_cate_product: '',    
            list_id_website_product: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    
    componentWillMount = () => {
        let url = API_SERVER_URL + 'admin/products/' + this.props.match.params.id + '/edit';
        axios.get(url).then(
            response => {
                this.setState(response.data);
                document.body.classList.add("loaded");
                const { dispatch } = this.props;
                if(this.state.img_future && (this.state.img_future!==null)){
                    dispatch({type: 'GET_IMAGE_FR', item_url_img: this.state.img_future});
                }else{
                    dispatch({ type: 'GET_IMAGE_FR', item_url_img: 'img-demo-vhpginseng.jpg'});
                }
                if(this.state.img_gellary1 && (this.state.img_gellary1!==null)){
                    dispatch({type: 'GET_IMAGE_GR1', item_url_img: this.state.img_gellary1});
                }else{
                    dispatch({ type: 'GET_IMAGE_GR1', item_url_img: 'img-demo-vhpginseng.jpg'});
                }
                if(this.state.img_gellary2 && (this.state.img_gellary2!==null)){
                    dispatch({type: 'GET_IMAGE_GR2', item_url_img: this.state.img_gellary2});
                }else{
                    dispatch({ type: 'GET_IMAGE_GR2', item_url_img: 'img-demo-vhpginseng.jpg'});
                }
                if(this.state.img_gellary3 && (this.state.img_gellary3!==null)){
                    dispatch({type: 'GET_IMAGE_GR3', item_url_img: this.state.img_gellary3});
                }else{
                    dispatch({ type: 'GET_IMAGE_GR3', item_url_img: 'img-demo-vhpginseng.jpg'});
                }
                if(this.state.img_gellary4 && (this.state.img_gellary4!==null)){
                    dispatch({type: 'GET_IMAGE_GR4', item_url_img: this.state.img_gellary4});
                }else{
                    dispatch({ type: 'GET_IMAGE_GR4', item_url_img: 'img-demo-vhpginseng.jpg'});
                }                                

            }
        ).catch(function(error){
            console.log(error);
        });                        
    }

    componentDidMount = () => {   
        document.body.className = '';
        document.body.classList.add("sidebar-toggled");
        document.body.id = "page-top";
        document.body.classList.add("isEditProductAdmin");     
        $('[data-toggle="tooltip"]').tooltip();        
    }

    handleSubmit (e) {
        e.preventDefault();
        let url = API_SERVER_URL + 'admin/products/' + this.props.match.params.id;
        const data = {
            id_properties: this.props.InputContentProduct.id_properties,
            list_id_cate_product: this.props.InputContentProduct.list_id_cate_product
        }
        axios.put(url, data).then(
            response => {
                alert('Amen oka!')
                console.log(response.data);
                // this.props.history.push('/users');
            }
        ).catch(
            function(error){
                console.log(error);
            }
        );
    }

    render() {        
        return (
            <div className="wrapper-full">
                <Preloader />
                {/*<!-- Page Wrapper -->*/}
                <div id="wrapper">
                    {/* Begin Left Side-bar */}
                    <LeftSideBar />
                    {/* End Left Side-bar */}

                    {/* <!-- Content Wrapper --> */}
                    <div id="content-wrapper" className="d-flex flex-column">

                        {/* <!-- Main Content --> */}
                        <div id="content">

                            {/* <!-- Topbar --> */}
                            <Navigation />
                            {/* <!-- End of Topbar --> */}

                            {/* <!-- Begin Page Content --> */}
                            <div className="container-fluid">
                                <form onSubmit={this.handleSubmit}>
                                    {/* <!-- Content Row --> */}
                                    <div className="row">
                                        {/* Left add product */}
                                        <div className="col-md-9 col-sm-9 col-12">  
                                            
                                            {/*<!-- Nội dung chính sản phẩm -->*/}
                                            <MainInsertContent 
                                                title={this.state.name}
                                                nameProduct={this.state.name}
                                                alias={this.state.alias}
                                                content={this.state.content}
                                            />
                                            
                                        </div>
                                        {/* End Left add product */}

                                        {/* Right add product */}
                                        <div className="col-md-3 col-sm-3 col-12">
                                            {/*<!-- Blox Thao tác -->*/}                                            
                                            <BtnInsertContent/>                                          
                                            {/*<!-- End Blox Thao tác -->*/}

                                            {/* Thêm thành phần + Thông tin sản xuất của sản phẩm */}
                                            <DesProductInput
                                                short_structure={this.state.short_structure}
                                                short_des={this.state.short_des}
                                                san_xuat={this.state.san_xuat}
                                                nhan_hieu={this.state.nhan_hieu}
                                                ma_vach={this.state.ma_vach}
                                                quy_cach={this.state.quy_cach}
                                                so_cong_bo={this.state.so_cong_bo}
                                            />                                       

                                        </div>                                    
                                        {/* End Right add product */}
                                    </div>
                                    <div className="row">
                                        {/* Giá sản phẩm */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            {/*<!-- Blox Giá bán -->*/}
                                            <PriceProduct
                                                price={this.state.price}
                                                price_sale={this.state.price_sale}
                                            />
                                            {/*<!-- Blox Thứ tự -->*/}
                                            <NumberDisplay
                                                number_display={this.state.number_display}
                                            />
                                        </div>                                        
                                        {/* End Giá sản phẩm */}
                                        
                                        {/* Blox Hình ảnh */}
                                        <div className="col-md-4 col-sm-4 col-12">                                            
                                            <ImgFuture/>
                                            {/*<!-- End Blox Hình ảnh -->*/}

                                            {/* Blox Gallery Hình ảnh */}
                                            <ImgGallery/>                                       
                                            {/* End Gallery Hình ảnh */}                                                                                        
                                        </div>                                        
                                        

                                        {/* Thao tác second */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            {/*<!-- Blox Thao tác -->*/}
                                            <BtnInsertContent/>                                            
                                        </div>                                        
                                        {/* End Thao tác second */}                                            
                                    </div>
                                    {/* Chọn danh mục / Chọn loại sản phẩm / Chọn Webstie */}
                                    <div className="row">
                                        {/* Danh Muc San Pham */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            {/*<!-- Blox Giá bán -->*/}
                                            <CategoryProduct
                                                list_id_cate_product={this.state.list_id_cate_product}
                                            />
                                        </div>                                        
                                        {/* End Danh Muc San Pham */}
                                        
                                        {/* Loai San Pham */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            {/*<!-- Blox Loai San Pham -->*/}
                                            <TypeProduct
                                                id_properties={this.state.id_properties}
                                            />
                                        </div>                                        
                                        {/* End Loai San Pham */}

                                        {/* Website hien thi second */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            {/*<!-- Blox Website hien thi -->*/}
                                            <WebDisplay
                                                list_id_website_product={this.state.list_id_website_product}
                                            />
                                        </div>                                        
                                        {/* End Website hien thi second */}                                            
                                    </div>                                           
                                    {/* End Chọn danh mục / Chọn loại sản phẩm / Chọn Webstie */} 
                                    {/* SEO Content */}
                                    <div className="row">
                                        {/* KEY SEO */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            <KeySeo
                                                key_seo={this.state.key_seo}
                                            />
                                        </div> 
                                        {/* END KEY SEO */}    

                                        {/* Description SEO */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            <DesSeo
                                                des_seo={this.state.des_seo}
                                            />
                                        </div>                                        
                                        {/* End Description SEO */}

                                        {/* Views / Day */}
                                        <div className="col-md-4 col-sm-4 col-12">
                                            <ViewsDay/>
                                        </div>
                                        {/* End Views / Day */}
                                    </div>
                                    {/* End SEO Content */}

                                </form>
                                {/* <div dangerouslySetInnerHTML={{__html: this.state.content}} /> */}
                                {/* Get Media Manager */}
                                <GetMediaModal/>

                            </div>
                            {/* <!-- /.container-fluid --> */}

                        </div>
                        {/* <!-- End of Main Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- End of Footer --> */}

                    </div>
                    {/* <!-- End of Content Wrapper --> */}

                </div>
                {/* <!-- End of Page Wrapper --> */}
            </div>
        );
    }
}

export default withRouter(connect(
    state => ({
        InputContentProduct: state.InputContentProduct,
        GetEditor: state.GetEditor,
        GetImageContent: state.GetImageContent        
    })
)(EditProduct));