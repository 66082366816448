import React, { Component } from 'react';

class ViewsDay extends Component {
    render() {
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Accordion -->*/}
                <a href="#idViewsDay" className="d-block card-header py-3 collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="idViewsDay">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="fas fa-street-view"></i> Vews / Day</h6>
                </a>
                {/*<!-- Content - Blox Views Day-->*/}
                <div className="collapse" id="idViewsDay">
                    <div className="card-body row">
                        <div className="col-12">
                            <img src={require('../../lib/lib-admin/img/DemoChart.JPG')} alt="..." className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewsDay;