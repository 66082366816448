import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo_admin from '../../lib/lib-admin/img/logo_admin.png'
import { funcHandle } from '../func.handle';
// import devToolsEnhancer from 'remote-redux-devtools';

class LeftSideBar extends Component {
    constructor(props) {
        super(props);
        this._Toggle = this._Toggle.bind(this);
    }

    _Toggle = () => {
        funcHandle._sidebarToggle();
    }

    render() {
        return (
            <div className="wrapSider-react">
                {/* <!-- Sidebar --> */}
                {/* <!-- bg-gradient-primary --> */}
                <ul className="navbar-nav bg-gradient-dark sidebar sidebar-dark accordion toggled" id="accordionSidebar">
            
                    {/* <!-- Sidebar - Brand --> */}
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center ad-logo" to="/home">
                        <div className="sidebar-brand-icon">
                        <img src={logo_admin} className="mx-auto d-block" alt=""/>
                        </div>        
                    </Link>
                
                    {/* <!-- Divider --> */}
                    <hr className="sidebar-divider my-0" />
                
                    {/* <!-- Nav Item - Dashboard --> */}
                    <li className="nav-item active">
                        <Link className="nav-link" to="/home">
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Thống kê</span></Link>
                    </li>
                
                    {/* <!-- Divider --> */}
                    <hr className="sidebar-divider" />
                
                    {/* <!-- Heading --> */}
                    <div className="sidebar-heading">
                        Website
                    </div>
                
                    {/* <!-- Nav Item - Pages Collapse Menu --> */}
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                        <i className="fas fa-fw fa-desktop"></i>
                        <span>Trang tĩnh</span>
                        </Link>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header"><i className="fas fa-fw fa-cog"></i>&nbsp;Quản lý trang tĩnh:</h6>
                            <Link className="collapse-item" to="#">Thêm trang</Link>
                            <Link className="collapse-item" to="#">Danh sách trang</Link>
                        </div>
                        </div>
                    </li>
                
                    {/* <!-- Nav Item - Utilities Collapse Menu --> */}
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
                        <i className="fas fa-fw fa-address-book"></i>
                        <span>Bài viết</span>
                        </Link>
                        <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header"><i className="fas fa-fw fa-cog"></i>&nbsp;Quản lý bài viết:</h6>
                            <Link className="collapse-item" to="#">Thêm bài viết</Link>
                            <Link className="collapse-item" to="#">Dah sách bài viết</Link>                    
                        </div>
                        </div>
                    </li>
                
                    {/* <!-- Divider --> */}
                    <hr className="sidebar-divider" />
                
                    {/* <!-- Heading --> */}
                    <div className="sidebar-heading">
                        Sản phẩm
                    </div>
                
                    {/* <!-- Nav Item - Pages Collapse Menu --> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="/add-product">
                        <i className="fas fa-fw fa-leaf"></i>
                        <span>Thêm sản phẩm</span>
                        </Link>
                    </li>
                
                    {/* <!-- Nav Item - Charts --> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="/list-products">
                        <i className="fas fa-fw fa-list-alt"></i>
                        <span>Danh sách sản phẩm</span></Link>
                    </li>
                
                    {/* <!-- Nav Item - Tables --> */}
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="true" aria-controls="collapsePages">
                        <i className="fas fa-fw fa-table"></i>
                        <span>Danh mục sản phẩm</span></Link>
                        <div id="collapsePages" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header"><i className="fas fa-fw fa-cog"></i>&nbsp;Quản lý bài viết:</h6>
                            <Link className="collapse-item" to="#">Loại sản phẩm</Link>
                            <Link className="collapse-item" to="#">Thuộc tính sản phẩm</Link>                    
                        </div>
                        </div>                  
                    </li>

                    {/* <!-- Divider --> */}
                    <hr className="sidebar-divider" />
                
                    {/* <!-- Heading --> */}
                    <div className="sidebar-heading">
                        Thư viện
                    </div>
                    
                    {/* <!-- Nav Item - Pages Collapse Menu --> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="/media-manager">
                        <i className="far fa-images"></i>
                        <span>Media</span>
                        </Link>
                    </li>    
                    {/* <!-- Nav Item - Pages Collapse Menu --> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="#">
                        <i className="fas fa-bug"></i>
                        <span>Log thao tác</span>
                        </Link>
                    </li>                                    
                
                    {/* <!-- Divider --> */}
                    <hr className="sidebar-divider d-none d-md-block" />
                
                    {/* <!-- Sidebar Toggler (Sidebar) --> */}
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle" onClick={this._Toggle}></button>
                    </div>                
            
                </ul>
                {/* <!-- End of Sidebar --> */}                
            </div>
        );
    }
}

export default LeftSideBar;