import React, { Component } from 'react';
// import tinymce from 'tinymce';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from "react-redux";
import $ from 'jquery';

class MainInsertContent extends Component {
    constructor(props) {
        super(props);            
        this.state = { content: '' };    
    }
    
    handleEditorChange = (e) => {
        // console.log('Content was updated:', e.target.getContent());
    }

    handleGetEditor(editor) {
        const { dispatch } = this.props;
        dispatch({ type: 'GET_EDITOR', editor: editor });
        dispatch({ type: 'IS_GET_IMAGE_CONTENT' });
    }    

    render() {        
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Add Product -->*/}
                <a href="#idContentAddProduct" className="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="idContentAddProduct">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="fas fa-fw fa-leaf"></i> {this.props.title}</h6>
                </a>
                {/*<!-- Main Content - Add Product -->*/}
                <div className="collapse show" id="idContentAddProduct">
                    <div className="card-body row">
                        <div className="col-md-12 col-sm-12 col-12 form-group has-feedback">
                            <input type="text"
                                className="form-control has-feedback-left"
                                id="inputTitleProduct"
                                required="required"
                                placeholder="Tiêu đề *"
                                defaultValue={this.props.nameProduct}
                                onChange={this.handleEditorChange}
                            />
                            <span className="fa fa-graduation-cap form-control-feedback left" aria-hidden="true"></span>
                        </div>
                        <div className="col-md-12 col-sm-12 col-12 form-group has-feedback">
                            <input type="text"
                                className="form-control has-feedback-left"
                                id="inputAliasProduct"
                                required="required"
                                placeholder="Đường dẫn *"
                                defaultValue={this.props.alias}
                                onChange={this.handleEditorChange}
                            />
                            <span className="fa fa-anchor form-control-feedback left" aria-hidden="true"></span>
                        </div>
                        {/*<!--Input Content Product-->*/}
                        <div className="col-md-12 col-sm-12 col-12">
                            <Editor
                                apiKey="619c8p0byud1ruc99nfehw4kuiqksvsvqq40rkk27bnvdae9"                                
                                id="ID_Editor"
                                init={{
                                    theme: "silver",
                                    height: 500,
                                    plugins: [
                                        "advlist autolink link image lists charmap print preview hr anchor pagebreak",
                                        "searchreplace wordcount visualblocks visualchars insertdatetime media nonbreaking",
                                        "table contextmenu directionality emoticons paste textcolor code image imagetools"
                                    ],
                                    setup: (editor) => { // on the setup of the TinyMCE plugin, add a button with the alias 'addImage'
                                        editor.ui.registry.addButton('addImage', {
                                            text: 'Insert', // the text to display alongside the button
                                            icon: 'image', // the icon to display alongside the button
                                            onAction: () => {
                                                this.handleGetEditor(editor);
                                                // var activeTextArea = editor; // onclick of this button, set the active textarea to that of which the button was pressed
                                                $('#mediaManagerModal').modal('show'); // show the file picker modal
                                                // editor.insertContent('<h1>Hello</h1>');
                                                document.body.classList.add("modal-overflow");
                                            }
                                        });
                                    },
                                    toolbar1: "undo redo | styleselect | bold italic underline strikethrough | forecolor backcolor removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | link unlink anchor | addImage | preview code",
                                    // toolbar2: "| link unlink anchor | addImage | preview code ",
                                    image_advtab: true,
                                    //automatic_uploads: true,
                                    images_reuse_filename: true,
                                    image_title: true,
                                }}                                
                                value={this.props.content}
                                onChange={this.handleEditorChange}
                            />
                        </div>
                        {/*<!--**********************Input Content Product-->*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        InputContentProduct: state.InputContentProduct
    })
)(MainInsertContent);