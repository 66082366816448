import React, { Component } from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import config from '../../config';
import ImageRow from './image.row';

const {API_SERVER_URL} = config;

class ListMedia extends Component {
    constructor(props) {
        super(props);
        this.state = { imageList: [] };
        this.fetchImages = this.fetchImages.bind(this);
    }

    componentWillMount() {
        document.body.classList.remove("loaded");        
        let url = API_SERVER_URL + 'admin/image/upload';
        axios.get(url)
        .then(response => {
            this.setState({ imageList: response.data })        
            const {imageList} = this.state;
            let newImageList = imageList.reverse();
            this.setState({
                imageList: newImageList.sort((a,b) => a.id > b.id)
            });   
            // console.log(response.data);
            
            const { dispatch } = this.props;        
            dispatch({ type: 'GET_IMAGE', item: this.state.imageList });        
            document.body.classList.add("loaded");                  
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    fetchImages() {
        if(this.props.GetImage instanceof Array) {                        
            return this.props.GetImage.map( (object,i) => {
                return (<ImageRow obj={object} key={i} index={i} />)
            } );
        }
    }
    
    render() {
        return (
            <div className="row getListMedia">                
                {this.fetchImages()}
            </div>
        );
    }
}

export default connect(state => ({
    GetImage: state.ImageReducer
  }))(ListMedia);