const defaultState = {
    imgFr:'img-demo-vhpginseng.jpg',
    imgGr1:'img-demo-vhpginseng.jpg',
    imgGr2:'img-demo-vhpginseng.jpg',
    imgGr3:'img-demo-vhpginseng.jpg',
    imgGr4:'img-demo-vhpginseng.jpg',
};
export const GetImageFrGr = (state = defaultState, action) => {
    switch (action.type) {
        case 'GET_IMAGE_FR':
          return {...state, imgFr: action.item_url_img};
        case 'GET_IMAGE_GR1':
            return {...state, imgGr1: action.item_url_img};
        case 'GET_IMAGE_GR2':
            return {...state, imgGr2: action.item_url_img};
        case 'GET_IMAGE_GR3':
            return {...state, imgGr3: action.item_url_img};
        case 'GET_IMAGE_GR4':
            return {...state, imgGr4: action.item_url_img};
        case 'REMOVE_IMAGE_FR':
            return {...state, imgFr: action.item_url_img};
        case 'REMOVE_IMAGE_GR1':
            return {...state, imgGr1: action.item_url_img};
        case 'REMOVE_IMAGE_GR2':
            return {...state, imgGr2: action.item_url_img};
        case 'REMOVE_IMAGE_GR3':
            return {...state, imgGr3: action.item_url_img};
        case 'REMOVE_IMAGE_GR4':
            return {...state, imgGr4: action.item_url_img};            
        default:
          return state
    }
}