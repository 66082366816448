import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
// import {Auth} from './fake.auth';
import LeftSideBar from './left-sidebar/left.sidebar';
import Navigation from './menu/navigation';
import Footer from './footer/footer';

class HomePageAdmin extends Component {   

    componentDidMount = (e) => {
        document.body.classList.add("sidebar-toggled");
        document.body.id = "page-top" ;
        document.body.classList.add("isHomePageAdmin");        
        document.body.classList.remove("isLogin");                
        // console.log('HomePage:' + Auth.isAuthenticated);        
        // console.log(this.props.location.pathname);                   
    }

    render() {   
        return (
          <div className="wrapper-full">
          {/*<!-- Page Wrapper -->*/}
          <div id="wrapper">                
            {/* Begin Left Side-bar */}
            <LeftSideBar/>
            {/* End Left Side-bar */}
            
            {/* <!-- Content Wrapper --> */}
            <div id="content-wrapper" className="d-flex flex-column">
        
              {/* <!-- Main Content --> */}
              <div id="content">
        
                {/* <!-- Topbar --> */}
                <Navigation/>
                {/* <!-- End of Topbar --> */}
        
                {/* <!-- Begin Page Content --> */}
                <div className="container-fluid">
        
                  {/* <!-- Page Heading --> */}
                  <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Thống kê</h1>
                    <Link to="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-download fa-sm text-white-50"></i> Generate Report</Link>
                  </div>
        
                  {/* <!-- Content Row --> */}
                  <div className="row">                    
        
                    {/* <!-- Earnings (Monthly) Card Example --> */}
                    <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Doanh số tháng trước</div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">40,000,000 VNĐ</div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-calendar fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
        
                    {/* <!-- Earnings (Monthly) Card Example --> */}
                    <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Doanh số tháng này</div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">21,050,000 VNĐ</div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
        
                    {/* <!-- Earnings (Monthly) Card Example --> */}
                    <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tiến độ công việc</div>
                              <div className="row no-gutters align-items-center">
                                <div className="col-auto">
                                  <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
                                </div>
                                <div className="col">
                                  <div className="progress progress-sm mr-2">
                                    <div className="progress-bar bg-info" role="progressbar" style={{width: '50%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
        
                    {/* <!-- Pending Requests Card Example --> */}
                    <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Khách hàng mới</div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-comments fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        
                  {/* <!-- Content Row --> */}
        
                </div>
                {/* <!-- /.container-fluid --> */}
        
              </div>
              {/* <!-- End of Main Content --> */}
        
              {/* <!-- Footer --> */}
              <Footer/>
              {/* <!-- End of Footer --> */}
        
            </div>
            {/* <!-- End of Content Wrapper --> */}
        
          </div>
          {/* <!-- End of Page Wrapper --> */}
        
          {/* <!-- Scroll to Top Button--> */}
          <button className="scroll-to-top rounded">
            <i className="fas fa-angle-up"></i>
          </button>
        
        </div>          
      );
    }
}

export default withRouter(HomePageAdmin);