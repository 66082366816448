import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import LoginForm from './components/login.form';
import NotFound from './components/not.found';
import HomePageAdmin from './components/home.page.admin';
import {ProtectedRoute} from './components/protected.route';
import Navigation from './components/menu/navigation';
import ListProducts from './components/products/list.products';
import AddProduct from './components/products/add.product';
import MediaManager from './components/media/media.manager';
import EditProduct from './components/products/edit.product';
import { createBrowserHistory } from 'history';
import ScrollToTop from './ScrollToTop';
 
const history = createBrowserHistory();

class App extends Component { 
  render() {
    return (
      <div className="wrapApp">        
        <Router history={history}> 
          <ScrollToTop>
            <Switch>                      
              <Route exact path='/' component={LoginForm} />     
              <ProtectedRoute exact path='/home' component={HomePageAdmin} /> 
              <ProtectedRoute exact path='/nav' component={Navigation} />       
              <ProtectedRoute exact path='/list-products' component={ListProducts} />    
              <ProtectedRoute exact path='/add-product' component={AddProduct} />
              <ProtectedRoute exact={false} strict={false} path='/edit-product/:id' component={EditProduct} />

              <ProtectedRoute exact path='/media-manager' component={MediaManager} />
              
              <Route path='*' component={NotFound} />
            </Switch>  
          </ScrollToTop>                                                                                                        
        </Router>    
      </div>      
    );
  }
}

export default (App);