import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import config from '../../config';

const API_SERVER_URL = config.API_SERVER_URL;

class CategoryProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            categories: [],
            listCate: []           
        };
        this.fetchRows = this.fetchRows.bind(this);        
    }    

    handleChange = (e) => {
        let isChecked = e.target.checked;                       
        const { dispatch } = this.props;
        // let joinCate = '';
        let getCate = [];
        if(isChecked){
            // Them value vao mang!    
            getCate = this.state.listCate.concat(e.target.value);            
        }else{
            // xoa value khoi mang            
            getCate = [...this.state.listCate]
            var index = getCate.indexOf(e.target.value)            
            if (index !== -1) {
                getCate.splice(index, 1);                
            }                  
        }        
        this.setState({listCate: getCate});
        // joinCate = getCate.join(', ');
        dispatch({ type: 'INPUT_LIST_ID_CATE_PRODUCT', input_content: getCate});
        // console.log(getCate);
    }    

    componentWillMount = () => {
        const { dispatch } = this.props;
        let url = API_SERVER_URL + 'admin/categories';
        axios.get(url).then(
            response => {                  
                this.setState({ categories: response.data });
                                    
                let getCategorries = this.props.list_id_cate_product;
                if(getCategorries.includes(",")){
                    let categories =  getCategorries.split(', ');  
                    this.setState({listCate: categories});       
                    dispatch({ type: 'INPUT_LIST_ID_CATE_PRODUCT', input_content: this.state.listCate});    
                }
                else{                                
                    this.setState({listCate: this.state.listCate.concat(getCategorries)});
                    dispatch({ type: 'INPUT_LIST_ID_CATE_PRODUCT', input_content: this.state.listCate});
                } 
                // console.log(getCategorries);                                                            
            }
        ).catch(function(error){
            console.log(error);
        }); 
    }

    fetchRows(list_id_cate_product) {               
        if (this.state.categories instanceof Array) {
            return this.state.categories.map((object, i) => {
                let classCheckBox = 'parent-cate-product';
                if(object.id_parent !== null){
                    classCheckBox = 'child-cate-product';
                }
                return (                    
                    <div key={i} index={i} className={"custom-control custom-checkbox mr-sm-2 " + classCheckBox}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id={'cateProduct' + object.id}
                            name={'cateProduct' + object.id}
                            value={object.id}
                            defaultChecked={list_id_cate_product.includes(object.id) ? true : false}
                            onChange={(e) => this.handleChange(e)}
                        />
                        <label className="custom-control-label" htmlFor={'cateProduct' + object.id}>{object.name}</label>
                    </div>
                );
            });
          }
    }

    render() {        
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Accordion -->*/}
                <a href="#idChonDanhMucSP" className="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="idChonDanhMucSP">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="fas fa-list"></i> Danh mục sản phẩm</h6>
                </a>
                {/*<!-- Content - Blox Giá bán -->*/}
                <div className="collapse show" id="idChonDanhMucSP">
                    <div className="card-body" id="idChonDanhMucSPBody">
                        {this.fetchRows(this.props.list_id_cate_product)}
                        {/* <div className="custom-control custom-checkbox mr-sm-2 parent-cate-product">
                            <input type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                            <label className="custom-control-label" htmlFor="customControlAutosizing">Hồng sâm</label>
                        </div>
                        <div className="custom-control custom-checkbox mr-sm-2 child-cate-product">
                            <input type="checkbox" className="custom-control-input" id="customControlAutosizing1" />
                            <label className="custom-control-label" htmlFor="customControlAutosizing1">Hồng sâm baby</label>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        InputContentProduct: state.InputContentProduct
    })
)(CategoryProduct);