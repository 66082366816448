import React, { Component } from 'react';
import config from '../../config';
import { connect } from "react-redux";
import axios from 'axios';
import $ from 'jquery';

const {SERVER_URL, API_SERVER_URL} = config;

class ImageRow extends Component {
    constructor(props){
        super(props);
        this.handleInsert = this.handleInsert.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChangeImg = this.handleChangeImg.bind(this);
    }

    // componentDidMount(){
    //     // isAddProductsAdmin
    //     if(document.body.classList.contains('isAddProductsAdmin')){
    //         ReactDOM.render(
    //             <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.handleInsert}><i className="fas fa-anchor"></i> Chèn ảnh</button>,
    //             document.getElementById('wrapBtnInsert' + this.props.obj.id)
    //         );
    //     }else {
    //         ReactDOM.render(
    //             '',
    //             document.getElementById('wrapBtnInsert' + this.props.obj.id)
    //         );            
    //     }    
    // }
    // handleESCKeyPress(e) {
    //     // console.log(e.keyCode);
    //     if(e.keyCode === 27) {
    //         console.log('ESC'); 
    //     }
    // }
    // componentDidMount() {
    //     document.addEventListener('keydown', this.handleESCKeyPress);                
    // }
    // componentWillUnmount() {
    //     document.removeEventListener('keydown', this.handleESCKeyPress);
    // }

    handleCloseModal(id){
        $('#myModal'+id).modal('hide');   
        return true; 
    }

    handleInsert(e){
        e.preventDefault();
        let url = SERVER_URL + 'storage/img/' + this.props.obj.alias;
        let title = this.props.obj.title;
        const { dispatch } = this.props;        
        dispatch({ type: 'IS_GET_IMAGE_CONTENT' });        
        document.body.classList.remove("modal-overflow");
        this.props.GetEditor.insertContent('<img alt="' + title + '" width="600" src="'+url+'"/>');             
    }

    handleChangeImg(e){
        e.preventDefault();
        document.body.classList.remove("modal-overflow");
        // console.log(this.props.GetTypeImgChange);
        const { dispatch } = this.props;
        dispatch({ type: 'IS_GET_IMAGE_FUTURE' });           
        dispatch({type: this.props.GetTypeImgChange, item_url_img: this.props.obj.alias});        
    }

    handleDelete(e) {
        e.preventDefault();                
        if(!window.confirm('Bạn có chắc chắn muốn xóa ảnh?')){
            return false;
        }
        document.body.classList.remove("loaded");
        $('#myModal'+this.props.obj.id).modal('hide'); 
        let url = API_SERVER_URL + 'admin/image/upload/' + this.props.obj.id;
        axios.delete(url).then(response => {            
            console.log(response.data);
            const { index, dispatch } = this.props;                
            dispatch({ type: 'REMOVE_IMAGE', index }); 
            document.body.classList.add("loaded");
        }).catch(function(error) {
            console.log(error);
        });
        console.log('Handle Delete Image!');        
    }

    render() {
        let btnInsertIMG = null, numberCl = 'col-12 d-flex justify-content-center';
        if(this.props.GetImageContent){
            btnInsertIMG = <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.handleInsert}><i className="fas fa-anchor"></i> Chèn ảnh</button>;
            numberCl = 'col-6 d-flex justify-content-start';
        }
        if(this.props.GetImageFuture){
            btnInsertIMG = <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.handleChangeImg}><i className="fas fa-clipboard-check"></i> Thay ảnh</button>;
            numberCl = 'col-6 d-flex justify-content-start';
        }        

        return (
            <div className="col-2">                
                <img className="img-fluid img-ViewDetail" src={SERVER_URL + "storage/img/"+this.props.obj.alias} alt={this.props.obj.title} data-toggle="modal" data-target={"#myModal"+this.props.obj.id}/>                
                {/* <!-- The Modal --> */}
                <div className="modal fade bd-example-modal-lg" id={"myModal"+this.props.obj.id} aria-hidden="true" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby={"myModal"+this.props.obj.id}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        
                        {/* <!-- Modal Header --> */}
                        <div className="modal-header">
                            <h5 className="modal-title">Chị tiết: {this.props.obj.title}</h5>
                            <button type="button" className="close" onClick={() => this.handleCloseModal(this.props.obj.id)}>&times;</button>
                        </div>
                        
                        {/* <!-- Modal body --> */}
                        <div className="modal-body row">
                            <div className="col-8 row">
                                <div className="col-6 d-flex justify-content-end" id={"wrapBtnInsert" + this.props.obj.id}>
                                    {btnInsertIMG}
                                </div>
                                <div className={numberCl}>
                                    <button type="button" className="btn btn-danger" onClick={this.handleDelete}><i className="fas fa-trash"></i> Xóa ảnh</button>
                                </div>                                                                
                                <div className="col-12 mt-2">
                                    <img className="img-fluid" src={SERVER_URL + "storage/img/"+this.props.obj.alias} alt={this.props.obj.title}/>   
                                </div>                                
                            </div>
                            <div className="col-4">
                                <div className="col-12">
                                    <h5>Tiêu đề hình ảnh:</h5>{this.props.obj.title}
                                </div>
                                <div className="col-12">
                                    <h5>Đường dẫn ảnh:</h5>{this.props.obj.alias} 
                                </div>
                                <button type="button" className="btn btn-info col-12 mt-2" onClick={() => this.handleCloseModal(this.props.obj.id)}>Lưu</button>                                
                            </div>                            
                        </div>
                        
                        {/* <!-- Modal footer --> */}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-danger" onClick={() => this.handleCloseModal(this.props.obj.id)}>Đóng</button>
                        </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export default ImageRow;
export default connect(
    state => ({        
        GetEditor: state.GetEditor,
        GetImageContent: state.GetImageContent,
        GetImageFuture: state.GetImageFuture,
        GetTypeImgChange: state.GetTypeImgChange
    })
)(ImageRow);