import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Preloader } from '../animate/Preloader';
import axios from 'axios';
import { connect } from "react-redux";
import LeftSideBar from '../left-sidebar/left.sidebar';
import Navigation from '../menu/navigation';
import Footer from '../footer/footer';
import ProductsRow from './product.row';
import config from '../../config';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

import $ from 'jquery';
$.DataTable = require('datatables.net-responsive-bs4');

const API_SERVER_URL = config.API_SERVER_URL;

class ListProducts extends Component {
  constructor(props) {
    super(props);
    this.state = { products: [] };
    this.fetchRows = this.fetchRows.bind(this);
  }

  componentWillMount(e) {
    document.body.className = '';    
    document.body.classList.add("sidebar-toggled");
    document.body.id = "page-top";
    document.body.classList.add("isListProductsAdmin");
    document.body.classList.remove("isLogin");    

    axios.get(API_SERVER_URL + 'admin/products')
      .then(response => {
        this.setState({ products: response.data });
        const {products} = this.state;
        let newProductsList = products.reverse();
        this.setState({
            products: newProductsList.sort((a,b) => a.id > b.id)
        });        
        const { dispatch } = this.props; 
        dispatch({ type: 'GET_PRODUCT', item: this.state.products });
        document.body.classList.add("loaded");
        // console.log(response.data);     
      })
      .catch(function (error) {
        console.log(error);
      });    
  }

  componentDidUpdate()
  {
    $(document).ready(function () {
      $('#idTableProducts').DataTable();
    });
  }

  fetchRows() {
    if (this.props.GetProduct instanceof Array) {
      return this.props.GetProduct.map((object, i) => {
        return (<ProductsRow obj={object} key={i} index={i} />)
      });
    }
  }  

  render() {
    return (
      <div className="wrapper-full">
        <Preloader />
        {/*<!-- Page Wrapper -->*/}
        <div id="wrapper">
          {/* Begin Left Side-bar */}
          <LeftSideBar />
          {/* End Left Side-bar */}

          {/* <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">

            {/* <!-- Main Content --> */}
            <div id="content">

              {/* <!-- Topbar --> */}
              <Navigation />
              {/* <!-- End of Topbar --> */}

              {/* <!-- Begin Page Content --> */}
              <div className="container-fluid">

                <div className="card shadow mb-4">
                  {/*<!-- Header - Add Product -->*/}
                  <a href="#idContentAddProduct" className="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="idContentAddProduct">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="fas fa-fw fa-list-alt"></i> Danh sách sản phẩm</h6>
                  </a>
                  {/*<!-- Main Content - Add Product -->*/}
                  <div className="collapse show" id="idContentAddProduct">
                    <div className="card-body row">
                      <div className="col-md-12 col-sm-12 col-12">
                        {/* Begin test data table */}
                        <table id="idTableProducts" className="table table-striped table-bordered dt-responsive nowrap" style={{ width: '100%' }}>
                          <thead>
                            <tr>
                              <th>Ảnh</th>
                              <th>Tên SP</th>
                              <th>Giá</th>
                              <th>Giá KM</th>
                              <th>Danh mục</th>
                              <th>Loại SP</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.fetchRows()}                            
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>Ảnh</th>
                              <th>Tên SP</th>
                              <th>Giá</th>
                              <th>Giá KM</th>
                              <th>Danh mục</th>
                              <th>Loại SP</th>
                              <th>Action</th>
                            </tr>
                          </tfoot>
                        </table>
                        {/* End test data table */}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              {/* <!-- /.container-fluid --> */}

            </div>
            {/* <!-- End of Main Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- End of Footer --> */}

          </div>
          {/* <!-- End of Content Wrapper --> */}

        </div>
        {/* <!-- End of Page Wrapper --> */}

        {/* <!-- Scroll to Top Button--> */}
        {/* <button className="scroll-to-top rounded">
              <i className="fas fa-angle-up"></i>
            </button> */}

      </div>
    );
  }
}

export default withRouter(
  connect(state => ({
    GetProduct: state.ProductReducer
  }))(ListProducts)
);