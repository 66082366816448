import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { currencyFormat } from '../func.handle';
import $ from 'jquery';
import config from '../../config';

const SERVER_URL = config.SERVER_URL;

class ProductsRow extends Component {
    componentDidMount() {
        $('[data-toggle="tooltip"]').tooltip();
    }
    removeTooltip() {
        $(".tooltip").remove();
    }
    render() {
        var priceFormat = '';
        var priceSaleFormat = '';
        var imgFuture = "img-demo-vhpginseng.jpg"
        var price = this.props.obj.price;
        var priceSale = this.props.obj.price_sale;
        if (this.props.obj.price !== null) {
            priceFormat = currencyFormat(price);
        }
        if (this.props.obj.price_sale !== null) {
            priceSaleFormat = currencyFormat(priceSale);
        }
        if (this.props.obj.img_future !== null) {
            imgFuture = this.props.obj.img_future;
        }

        return (
            <tr>
                <td><img src={SERVER_URL + "storage/img/" + imgFuture} alt={this.props.obj.name} width="50" height="50" /></td>
                <td>{this.props.obj.name}</td>
                <td>{priceFormat}</td>
                <td>{priceSaleFormat}</td>
                <td>{this.props.obj.name_cate_product}</td>
                <td>{this.props.obj.name_properties_product}</td>
                <td>
                    <Link className='btn btn-warning btn-circle'
                        onClick={this.removeTooltip}
                        to={'/edit-product/' + this.props.obj.id}

                        data-toggle="tooltip" title="Sửa sản phẩm!">
                        <i className="fas fa-tools"></i>
                    </Link>
                    <button className='btn btn-danger btn-circle'
                        data-toggle="tooltip" title="Xóa sản phẩm!">
                        <i className="fas fa-trash"></i>
                    </button>
                </td>
            </tr>
        );
    }
}

export default withRouter(ProductsRow);