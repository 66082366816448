import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <p style={{marginBottom:"10px"}}>Copyright &copy; TrangIT - Hệ thống quản trị web - Design by TrangIT</p>
                        <p style={{marginBottom:"0px"}}>Sponsored by VHP Group</p>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;