import React, { Component } from 'react';
import { currencyFormat } from '../func.handle';
import { connect } from "react-redux";

class PriceProduct extends Component {
    handleInputChange = (e) => {
        // console.log('Content was updated:', e.target.getContent());
    }

    render() {
        var priceFormat = '';
        var priceSaleFormat = '';                
        if (this.props.price && this.props.price !== null) {
            priceFormat = currencyFormat(this.props.price);
        }
        if (this.props.price_sale && this.props.price_sale !== null) {
            priceSaleFormat = currencyFormat(this.props.price_sale);
        }        
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Accordion -->*/}
                <a href="#idPriceProduct" className="d-block card-header py-3 collapsed" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="idPriceProduct">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="fas fa-money-check-alt"></i> Giá bán</h6>
                </a>
                {/*<!-- Content - Blox Giá bán -->*/}
                <div className="collapse" id="idPriceProduct">
                    <div className="card-body row">
                        <div className="col-md-12 col-sm-12 col-12 form-group has-feedback">
                            <input type="text"
                                className="form-control has-feedback-left"
                                id="inputPriceProduct"
                                placeholder="Giá bán"
                                defaultValue={priceFormat}
                                onChange={this.handleInputChange}
                            />
                            <span className="fas fa-dollar-sign form-control-feedback left" aria-hidden="true"></span>
                        </div>
                        <div className="col-md-12 col-sm-12 col-12 form-group has-feedback">
                            <input type="text"
                                className="form-control has-feedback-left"
                                id="inputSalePriceProduct"
                                placeholder="Giá KM"
                                defaultValue={priceSaleFormat}
                                onChange={this.handleInputChange}
                            />
                            <span className="fas fa-funnel-dollar form-control-feedback left" aria-hidden="true"></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({        
        InputContentProduct: state.InputContentProduct
    })
)(PriceProduct);