const defaultState = {
    isAddProduct: false,
    isAddPage: false,
    isAddPost: false,
    isEditProduct: false,
    isEditPage: false,
    isEditPost: false,
};
export const HandleCancelActionInsert = (state = defaultState, action) => {
    switch (action.type) {
        case 'isAddProductsAdmin':
          return {...state, isAddProduct: !state.isAddProduct};                 
        default:
          return state
    }
}