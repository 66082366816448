import config from '../../config';

const {API_SERVER_URL} = config;

export const PostData = (type, userData) => {

    let BaseURL = API_SERVER_URL + 'admin/';
    // let BaseURL = 'http://localhost:8000/api/admin/';
    // let BaseURL = 'http://phutungotovinfast.vn/api/admin/'
    // let BaseURL = 'http://api.daedong.com.vn/api/admin/';
    var myInit = {
        method: 'POST',            
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },          
        body: JSON.stringify(userData),        
    }
    var myRequest = new Request(BaseURL+type, myInit)
    
    return new Promise((resolve, reject) => {
        fetch(myRequest) 
        .then(response => response.json())            
        .then((responseJson) => resolve(responseJson))
        .catch((error) => reject(error));
    });

}

 