import React, { Component } from 'react';
import {connect} from 'react-redux';

class NumberDisplay extends Component {
    handleInputChange = (e) =>{

    }

    render() {
        return (
            <div className="card shadow mb-4">
                {/*<!-- Header - Accordion -->*/}
                <a href="#idThutu" className="d-block card-header py-3 collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="idThutu">
                    <h6 className="m-0 font-weight-bold text-primary"><i className="fas fa-qrcode"></i> Thứ tự hiển thị</h6>
                </a>
                {/*<!-- Content - Blox Mã vạch / Thứ tự -->*/}
                <div className="collapse" id="idThutu">
                    <div className="card-body row">                        
                        <div className="col-md-12 col-sm-12 col-12 form-group has-feedback">
                            <input type="text"
                                className="form-control has-feedback-left"
                                id="inputBarcode"
                                placeholder="Thứ tự"                                
                                defaultValue={this.props.number_display}                                
                                onChange={this.handleInputChange}
                            />
                            <span className="fas fa-sort-amount-up form-control-feedback left" aria-hidden="true"></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({        
        InputContentProduct: state.InputContentProduct
    })
)(NumberDisplay);